import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const PriceList = () => {
  const { t } = useTranslation();

  return (
    <div className='mt-4'>
      <h3 className='text-center mb-4'>{t('products.priceList.title')}</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t('products.priceList.columns.name')}</th>
            <th>{t('products.priceList.columns.cost')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('products.priceList.items.documentProcessing')}</td>
            <td>{t('products.priceList.items.oneCredit')}</td>
          </tr>
          <tr>
            <td>{t('products.priceList.items.medicalReport')}</td>
            <td>{t('products.priceList.items.oneCredit')}</td>
          </tr>
          <tr>
            <td>{t('products.priceList.items.healthReport')}</td>
            <td>{t('products.priceList.items.oneCredit')}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default PriceList;
