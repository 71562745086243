import { baseApi } from './baseApi';
import i18n from 'i18next';

const subscriptionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSubscriptionStatus: builder.query({
      query: () => ({
        url: '/permission-status',
        method: 'GET',
      }),
      transformResponse: (data) => ({
        ...data,
        current_subs: data.current_subs.map((sub) => ({
          ...sub,
          periodType: sub.sub_freq.toLowerCase().includes('month') ? 'month' : 'year',
          currency: sub.currency === 'pln' ? 'zł' : sub.currency,
        })),
      }),
    }),

    fetchProducts: builder.query({
      query: ({ getAllProducts }) => ({
        url: '/stripe/subscribable-product',
        method: 'GET',
        headers: getAllProducts ? { Authorization: null } : {}, // skip Authorization header to fetch all products
      }),
      transformResponse: (data) =>
        data.map((product) => ({
          ...product,
          periodType:
            product.freq === null
              ? 'one-off'
              : product.freq.toLowerCase().includes('month')
                ? 'month'
                : 'year',
          currency: product.currency === 'pln' ? 'zł' : product.currency,
        })),
    }),

    fetchCounter: builder.query({
      query: ({ offset, limit }) => ({
        url: `/counter?limit=${limit}&offset=${offset}`,
        method: 'GET',
      }),
    }),

    fetchStripePortal: builder.mutation({
      query: () => ({
        url: '/stripe/customer-portal/', // on purpose use of '/' at the end
        method: 'POST',
      }),
    }),

    // Endpoint for subscription products
    fetchStripeCheckout: builder.mutation({
      query: ({ price_id }) => ({
        url: '/stripe/checkout/', // on purpose use of '/' at the end
        method: 'POST',
        body: { price_id },
        credentials: 'include',
      }),
      transformResponse: (response) => response,
    }),

    // Endpoint for one-off products
    fetchOneTimeCheckout: builder.mutation({
      query: ({ price_id }) => {
        const currentLanguage = i18n.language || 'pl';
        return {
          url: '/payments/one-time-purchases/create_checkout_session/',
          method: 'POST',
          body: {
            price_id,
            language: currentLanguage,
          },
          credentials: 'include',
        };
      },
      transformResponse: (response) => {
        // The one-time purchase endpoint might return a different structure
        // It could include fields like product, amount_paid, currency, credits_added, status
        // We need to transform it to a consistent format
        // If the response already has a session_id or checkout_url, return it as is
        if (response.session_id || response.checkout_url || response.redirect_url) {
          return response;
        }
        // Otherwise, try to extract the relevant information
        return {
          ...response,
          // Add any necessary transformations here
        };
      },
    }),

    consentConsumerRights: builder.mutation({
      query: (consent) => ({
        url: '/consumer-rights-consent/', // on purpose use of '/' at the end
        method: 'POST',
        body: { consent },
      }),
    }),
  }),
});

export const {
  useFetchSubscriptionStatusQuery,
  useFetchProductsQuery,
  useFetchCounterQuery,
  useFetchStripePortalMutation,
  useFetchStripeCheckoutMutation,
  useFetchOneTimeCheckoutMutation,
  useConsentConsumerRightsMutation,
} = subscriptionApi;
