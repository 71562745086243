// import React, { useCallback } from 'react';
// import { Card, ListGroup, Button } from 'react-bootstrap';
// import { useTranslation } from 'react-i18next';
// import { useFetchSubscriptionStatusQuery, useFetchStripePortalMutation } from '#store';
// import SubscriptionPlansItem from './SubscriptionPlansItem';

// const SubscriptionPlans = () => {
//   const { t } = useTranslation();

//   const { data } = useFetchSubscriptionStatusQuery(undefined, { refetchOnMountOrArgChange: true });

//   const [fetchStripePortal] = useFetchStripePortalMutation();

//   const goToStripePortal = useCallback(async () => {
//     const { data } = await fetchStripePortal();

//     if (data?.url) window.location.href = data.url;
//   }, [fetchStripePortal]);

//   if (!data || !data.stripe_user) return;

//   return (
//     <Card border='0' className='shadow p-0 p-md-4 h-100'>
//       <Card.Header className='border-bottom p-3 d-flex'>
//         <h5>{t('account.subscription-plan.list')}</h5>
//       </Card.Header>
//       <Card.Body className='px-0 py-0'>
//         <ListGroup>
//           {data.current_subs?.map((subscription, index) => (
//             <SubscriptionPlansItem
//               key={subscription.sub_id}
//               subscription={subscription}
//               isLast={index === data.current_subs.length - 1}
//             />
//           ))}
//         </ListGroup>
//       </Card.Body>
//       <Card.Footer className='d-flex justify-content-center'>
//         <Button variant='secondary' onClick={goToStripePortal}>
//           {t('account.subscription-plan.manage')}
//         </Button>
//       </Card.Footer>
//     </Card>
//   );
// };

// export default React.memo(SubscriptionPlans);

import React, { useCallback, useState } from 'react';
import { Card, ListGroup, Button, Spinner, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFetchSubscriptionStatusQuery, useFetchStripePortalMutation } from '#store';
import axios from 'axios';
import SubscriptionPlansItem from './SubscriptionPlansItem';
import { useSelector } from 'react-redux';
import { API_URL } from '#const';

const SubscriptionPlans = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { userToken } = useSelector((state) => state.auth);

  const { data } = useFetchSubscriptionStatusQuery(undefined, { refetchOnMountOrArgChange: true });

  const [fetchStripePortal] = useFetchStripePortalMutation();

  // Original Stripe portal for subscription management (now using the enhanced endpoint)
  const goToStripePortal = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      // Get the current URL for return URL
      const currentUrl = window.location.origin;
      const returnUrl = `${currentUrl}/account`;
      // Get the current language from i18n
      const currentLanguage = i18n.language;
      // Use the enhanced portal endpoint with subscription focus
      const response = await axios.post(
        `${API_URL}/payments/enhanced-customer-portal/create_portal_session/`,
        {
          return_url: returnUrl,
          language: currentLanguage,
          show_invoice_history: false, // Disable invoice history to focus on subscriptions
        },
        {
          headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
      // Redirect to the portal URL
      if (response.data) {
        const redirectUrl = response.data.url || response.data.URL;
        if (redirectUrl) {
          console.log('Redirecting to subscription portal:', redirectUrl);
          // Open in the same window
          window.location.href = redirectUrl;
          setLoading(false);
          return;
        }
      }
      // If we get here, we didn't find a valid URL
      console.error('No valid URL found in response:', response.data);
      setError(t('account.subscription-plan.portal-error') + ': No valid URL in response');
      setLoading(false);
    } catch (err) {
      console.error(
        'Error opening subscription portal:',
        err.response ? err.response.data : err.message,
      );
      // Show more specific error message to help with debugging
      const errorMessage = err.response
        ? `${t('account.subscription-plan.portal-error')}: ${err.response.status} ${
            err.response.statusText
          }`
        : `${t('account.subscription-plan.portal-error')}: ${err.message}`;
      setError(errorMessage);
      setLoading(false);
    }
  }, [t, userToken, i18n]);

  // Enhanced Stripe portal that includes one-time purchases
  const goToEnhancedPortal = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      // Get the current URL for return URL
      const currentUrl = window.location.origin;
      const returnUrl = `${currentUrl}/account`;
      // Get the current language from i18n
      const currentLanguage = i18n.language;
      // Use the full API URL with the correct structure and include language
      const response = await axios.post(
        `${API_URL}/payments/enhanced-customer-portal/create_portal_session/`,
        {
          return_url: returnUrl,
          language: currentLanguage,
          show_invoice_history: true, // Show invoice history for complete billing view
        },
        {
          headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
      // Add debugging to see the exact response
      console.log('Enhanced portal response:', response.data);
      // Redirect to the portal URL - check both url and URL properties
      if (response.data) {
        const redirectUrl = response.data.url || response.data.URL;
        if (redirectUrl) {
          console.log('Redirecting to enhanced portal:', redirectUrl);
          // Use window.open instead of location.href to avoid potential navigation issues
          window.open(redirectUrl, '_blank');
          setLoading(false);
          return;
        }
      }
      // If we get here, we didn't find a valid URL
      console.error('No valid URL found in response:', response.data);
      setError(t('account.subscription-plan.portal-error') + ': No valid URL in response');
      setLoading(false);
    } catch (err) {
      console.error(
        'Error opening enhanced portal:',
        err.response ? err.response.data : err.message,
      );
      // Show more specific error message to help with debugging
      const errorMessage = err.response
        ? `${t('account.subscription-plan.portal-error')}: ${err.response.status} ${
            err.response.statusText
          }`
        : `${t('account.subscription-plan.portal-error')}: ${err.message}`;
      setError(errorMessage);
      setLoading(false);
    }
  }, [t, userToken, i18n]);

  if (!data || !data.stripe_user) return null;

  return (
    <Card border='0' className='shadow p-0 p-md-4 h-100'>
      <Card.Header className='border-bottom p-3 d-flex'>
        <h5>{t('account.subscription-plan.list')}</h5>
      </Card.Header>
      <Card.Body className='px-0 py-0'>
        <ListGroup>
          {data.current_subs?.map((subscription, index) => (
            <SubscriptionPlansItem
              key={subscription.sub_id}
              subscription={subscription}
              isLast={index === data.current_subs.length - 1}
            />
          ))}
        </ListGroup>
      </Card.Body>
      <Card.Footer className='d-flex flex-column align-items-center'>
        <ButtonGroup className='mb-2'>
          <Button variant='logo-green' onClick={goToStripePortal} disabled={loading}>
            {loading ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                <span className='ms-2'>{t('common.loading')}</span>
              </>
            ) : (
              t('account.subscription-plan.manage')
            )}
          </Button>
          {/* <Button 
            variant='primary' 
            onClick={goToEnhancedPortal}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="ms-2">{t('common.loading')}</span>
              </>
            ) : (
              t('account.subscription-plan.view-all-billing')
            )}
          </Button> */}
        </ButtonGroup>
        {error && <div className="alert alert-danger mt-2 w-100">{error}</div>}
        {/* <small className="text-muted mt-1">
          {t('account.subscription-plan.billing-info')}
        </small> */}
      </Card.Footer>
    </Card>
  );
};

export default React.memo(SubscriptionPlans);
