import React from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const Pagination = ({
  pages,
  limit,
  pageNumbers,
  totalItems,
  currentPage,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const startItem = totalItems > 0 ? currentPage * limit + 1 : 0;
  const endItem = Math.min(startItem + limit - 1, totalItems);

  return (
    <div className='d-flex flex-column flex-lg-row align-items-center justify-content-between'>
      <small className='fw-normal small mb-3 mb-lg-0 me-lg-4'>
        {totalItems > 0 ? (
          <>
            {t('pagination.showing-items')} <b>{startItem}</b> {t('pagination.to')} <b>{endItem}</b>{' '}
            {t('pagination.out-of')} <b>{totalItems}</b> {t('pagination.documents')}
          </>
        ) : (
          t('pagination.no-items')
        )}
      </small>
      <BootstrapPagination className='mb-0'>
        <BootstrapPagination.Prev
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 0}
          className='cursor-pointer'
        />
        {pages.map((p) => (
          <BootstrapPagination.Item
            key={p}
            active={p === currentPage}
            onClick={() => onPageChange(p)}
            className='cursor-pointer'
          >
            {p + 1}
          </BootstrapPagination.Item>
        ))}
        <BootstrapPagination.Next
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === pageNumbers - 1}
        />
      </BootstrapPagination>
    </div>
  );
};
