import React, { useEffect, useState } from 'react';
import { Card, Badge, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFetchSubscriptionStatusQuery } from '#store';

const CreditsStatus = () => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useFetchSubscriptionStatusQuery();
  const [prevCredits, setPrevCredits] = useState(null);
  const [showHighlight, setShowHighlight] = useState(false);

  // Track changes in credits to highlight when they change
  useEffect(() => {
    if (data && prevCredits !== null) {
      const currentTotal = data.extra_credits + data.creds - data.counts;
      const previousTotal = prevCredits;
      if (currentTotal > previousTotal) {
        // Credits increased - show highlight effect
        setShowHighlight(true);
        // Remove highlight after animation completes
        const timeoutId = setTimeout(() => {
          setShowHighlight(false);
        }, 3000);
        return () => clearTimeout(timeoutId);
      }
    }
    // Update previous credits value
    if (data) {
      setPrevCredits(data.extra_credits + data.creds - data.counts);
    }
  }, [data, prevCredits]);

  // Show loading spinner when initially loading
  if (isLoading && !data) {
    return (
      <Card border='0' className='shadow p-0 p-md-4 h-100'>
        <Card.Body className='d-flex flex-column justify-content-center align-items-center text-center'>
          <Spinner animation='border' />
        </Card.Body>
      </Card>
    );
  }

  if (!data) return null;

  const remainingCreds = data.extra_credits + data.creds - data.counts;
  const remainingSubCreds = Math.max(0, data.creds - data.counts);
  const extraCredits = data.extra_credits || 0;

  // Define highlight animation style
  const highlightStyle = showHighlight
    ? {
        animation: 'pulse 1.5s ease-in-out',
        boxShadow: '0 0 15px rgba(40, 167, 69, 0.7)',
      }
    : {};

  // Add keyframes for pulse animation to document if not already added
  if (showHighlight && !document.getElementById('pulse-animation')) {
    const style = document.createElement('style');
    style.id = 'pulse-animation';
    style.innerHTML = `
      @keyframes pulse {
        0% { transform: scale(1); }
        50% { transform: scale(1.05); }
        100% { transform: scale(1); }
      }
    `;
    document.head.appendChild(style);
  }

  return (
    <Card border='0' className='shadow p-0 p-md-4 h-100' style={highlightStyle}>
      <Card.Body className='d-flex flex-column justify-content-center align-items-center text-center'>
        <h2 className='mb-3'>
          {t('account.credits-status.title')}
          {isFetching && <Spinner animation='border' size='sm' className='ms-2' />}
        </h2>

        <div className='my-3'>
          <div className='mb-3'>
            <h4>{t('account.credits-status.subscription-credits')}</h4>
            <Badge
              bg='primary'
              className='p-2 fs-5'
              style={showHighlight ? { animation: 'pulse 1.5s ease-in-out' } : {}}
            >
              {remainingSubCreds}
            </Badge>
          </div>
          <div className='mb-3'>
            <h4>{t('account.credits-status.extra-credits')}</h4>
            <Badge
              bg='success'
              className='p-2 fs-5'
              style={showHighlight ? { animation: 'pulse 1.5s ease-in-out' } : {}}
            >
              {extraCredits}
            </Badge>
          </div>
          <div className='mb-3'>
            <h4>{t('account.credits-status.total-credits')}</h4>
            <Badge
              bg='secondary'
              className='p-2 fs-5'
              style={showHighlight ? { animation: 'pulse 1.5s ease-in-out' } : {}}
            >
              {remainingCreds}
            </Badge>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default React.memo(CreditsStatus);
