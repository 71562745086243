import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { Row, Col, Button, Spinner, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetchHealthReportsQuery } from '#store';
import { HealthReportsGallery, Pagination } from '#components';
import { Routes } from '#Routes';

const LIMIT_PER_PAGE = 5;

const HealthReportsListPage = ({ limit = LIMIT_PER_PAGE }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);

  const offset = currentPage * limit;

  const { data, isLoading } = useFetchHealthReportsQuery(
    { offset, limit },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const totalItems = data?.count ?? 0;
  const pageNumbers = Math.ceil(totalItems / limit);
  const pages = Array.from(Array(pageNumbers).keys());

  const onPageChange = (p) => setCurrentPage(p);

  return (
    <div>
      <div className='d-block mb-4'>
        <h4>{t('health-report-list-page.header')}</h4>
        <p className='mb-0'>{t('health-report-list-page.text')}</p>
      </div>
      <Row className='justify-content-between align-items-center mb-4'>
        <Col xs={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className='d-grid'>
          <Button
            variant='logo-green'
            size='lg'
            onClick={() => {
              navigate(Routes.HealthReportCreate.path);
            }}
            className='btn-block d-flex align-items-center justify-content-center'
          >
            <PlusIcon className='icon icon-xs me-2' /> {t('health-report-list-page.create-button')}
          </Button>
        </Col>
      </Row>
      <Card border='0' className='table-wrapper shadow overflow-hidden'>
        <Card.Body>
          {isLoading ? (
            <div className='d-flex justify-content-center align-items-center m-5'>
              <Spinner />
              <span className='ms-2'>{t('health-report-list-page.loading')}</span>
            </div>
          ) : (
            <>
              <HealthReportsGallery data={data?.results} />
              <Card.Footer className='px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between'>
                <Pagination
                  pages={pages}
                  limit={LIMIT_PER_PAGE}
                  pageNumbers={pageNumbers}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                />
              </Card.Footer>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default React.memo(HealthReportsListPage);
