import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner, Alert } from 'react-bootstrap';
import { CreditsStatus, SubscriptionPlans, AccountDetails, CreditsHistory } from '#components';
import { useFetchSubscriptionStatusQuery } from '#store';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AccountPage = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [refreshing, setRefreshing] = useState(false);
  const [refreshAttempts, setRefreshAttempts] = useState(0);
  const [showRefreshMessage, setShowRefreshMessage] = useState(false);
  const { isLoading, refetch, data: subscriptionData } = useFetchSubscriptionStatusQuery();

  // Determine if we're coming from checkout
  const isFromCheckout =
    location.search.includes('fromCheckout') ||
    location.search.includes('checkout_success') ||
    location.search.includes('payment_success') ||
    document.referrer.includes('checkout') ||
    document.referrer.includes('stripe.com');

  // Refetch subscription status data when component mounts or when returning from checkout
  useEffect(() => {
    if (isFromCheckout) {
      setShowRefreshMessage(true);
      setRefreshing(true);
      // Initial refetch
      refetch();
      // Set up an interval to refetch data every few seconds
      const intervalId = setInterval(() => {
        refetch();
        setRefreshAttempts((prev) => {
          const newCount = prev + 1;
          // Stop after 5 attempts (15 seconds)
          if (newCount >= 5) {
            clearInterval(intervalId);
            setRefreshing(false);
          }
          return newCount;
        });
      }, 3000); // Refetch every 3 seconds
      // Cleanup interval
      return () => {
        clearInterval(intervalId);
        setRefreshing(false);
      };
    }
  }, [refetch, isFromCheckout]);

  // Hide refresh message after 10 seconds
  useEffect(() => {
    if (showRefreshMessage) {
      const timeoutId = setTimeout(() => {
        setShowRefreshMessage(false);
      }, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [showRefreshMessage]);

  if (isLoading && !refreshing) {
    return (
      <div className='text-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {showRefreshMessage && isFromCheckout && (
        <Alert variant='logo-light-green' dismissible onClose={() => setShowRefreshMessage(false)}>
          {refreshing ? (
            <>
              <Spinner animation='border' size='sm' className='me-2' />{' '}
              {t('account.credits-status.updating-account-info')}
            </>
          ) : (
            <>{t('account.credits-status.account-updated-with-purchase')}</>
          )}
        </Alert>
      )}
      <Row>
        <Col className='mb-4'>
          <CreditsStatus />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6} className='mb-4 flex-grow-1'>
          <SubscriptionPlans />
        </Col>
        <Col xs={12} lg={6} className='mb-4 flex-grow-1'>
          <CreditsHistory />
        </Col>
      </Row>
      <Row>
        <Col className='mb-4'>
          <AccountDetails />
        </Col>
      </Row>
    </>
  );
};

export default AccountPage;
