import React from 'react';
import { Button, FormCheck, Card, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import moment from 'moment';

const SubscriptionCheckoutModal = ({ onUnselectProduct, onPayment, product }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  if (!product) return;

  const { name, price, currency, periodType } = product;
  const isOneOffProduct = periodType === 'one-off';

  const nextPayment = moment()
    .add(1, periodType === 'year' ? 'years' : 'months')
    .format('DD MMMM YYYY');

  const tag = t(`products.plan.${name}.tag`);

  const handleFormSubmit = (data) => {
    console.log('Form submitted with data:', data);
    try {
      onPayment(data);
    } catch (error) {
      console.error('Error in form submission:', error);
    }
  };

  return (
    <Modal
      centered
      size='lg'
      className='modal-tertiary text-white'
      show={Boolean(product)}
      onHide={onUnselectProduct}
      backdrop='static'
      keyboard={false}
    >
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Body className='text-center pt-5'>
          <h2 className='display-3'>
            {t(`products.plan.${name}.title`)}{' '}
            {tag && (
              <span className='pro-badge subscription-badge bg-secondary fw-bolder'>{tag}</span>
            )}
          </h2>
          <p className='mt-4'>
            {isOneOffProduct ? (
              <>
                {t('payment.one-time-payment')}{' '}
                <span className='fw-bold'>{`${price / 100} ${currency}`}</span>
              </>
            ) : (
              <>
                {t('subscription.next-payment')}{' '}
                <span className='fw-bold'>
                  {`${price / 100} ${currency}`} / {t(`periods.${periodType}`).toLowerCase()}
                </span>
                {` ${t('subscription.will-expire').toLowerCase()} ${nextPayment}.`}
              </>
            )}
          </p>
        </Modal.Body>
        <Modal.Footer className='flex justify-content-center mx-lg-5'>
          <div className='mb-4'>
            <Button
              variant='outline-white'
              size='md'
              className='me-2'
              onClick={(e) => {
                e.preventDefault();
                onUnselectProduct();
              }}
            >
              {isOneOffProduct ? t('payment.cancel') : t('subscription.change-plan')}
            </Button>
            <Button
              variant='secondary'
              size='md'
              className='px-4'
              type='submit'
              disabled={isSubmitting}
            >
              {isOneOffProduct ? t('payment.buy-now') : t('subscription.payment')}
            </Button>
          </div>
          <FormCheck type='checkbox' className='mb-4'>
            <FormCheck.Input required id='consumer-rights' {...register('consent')} />
            <FormCheck.Label htmlFor='consumer-rights' className='fw-normal mb-0'>
              <Card.Text className='font-small'>
                {isOneOffProduct
                  ? t('consent.consumer-rights-one-off')
                  : t('consent.consumer-rights')}
              </Card.Text>
            </FormCheck.Label>
          </FormCheck>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default React.memo(SubscriptionCheckoutModal);
