import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

const HowItWorksPage = () => {
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      const language = searchParams.get('language');
      if (language && ['en', 'pl'].includes(language)) {
        i18n.changeLanguage(language);
      }
    }
  }, [lang, i18n, searchParams]);

  // Define the process images based on language
  const getProcessImages = () => {
    const isEnglish = i18n.language === 'en';
    return [
      {
        id: 'digitization',
        title: t('howItWorks.digitization.title', 'Medical Document Digitization'),
        image: isEnglish
          ? '/how_it_works/CYFRYZACJA-DOKUMENTU-MEDYCZNEGO-ANG.png'
          : '/how_it_works/CYFRYZACJA-DOKUMENTU-MEDYCZNEGO.png',
        description: t(
          'howItWorks.digitization.description',
          'The process of converting physical medical documents into digital format.',
        ),
        video: '/how_it_works/create_lab_document.mp4',
        videoTitle: t(
          'howItWorks.digitization.videoTitle',
          'Watch how document digitization works',
        ),
      },
      {
        id: 'aiReport',
        title: t('howItWorks.aiReport.title', 'AI Report Creation for Medical Documents'),
        image: isEnglish
          ? '/how_it_works/TWORZENIE-RAPORTU-AI-DO-DOKUMENTU-MEDYCZNEGO-ANG.png'
          : '/how_it_works/TWORZENIE-RAPORTU-AI-DO-DOKUMENTU-MEDYCZNEGO.png',
        description: t(
          'howItWorks.aiReport.description',
          'How AI analyzes medical documents to create comprehensive reports.',
        ),
        video: '/how_it_works/create_lab_document_report.mp4',
        videoTitle: t('howItWorks.aiReport.videoTitle', 'Watch how AI report creation works'),
      },
      {
        id: 'healthReport',
        title: t('howItWorks.healthReport.title', 'AI Health Report Creation'),
        image: isEnglish
          ? '/how_it_works/TWORZENIE-RAPORTU-ZDROWOTNEGO-AI-ANG.png'
          : '/how_it_works/TWORZENIE-RAPORTU-ZDROWOTNEGO-AI.png',
        description: t(
          'howItWorks.healthReport.description',
          'The process of generating personalized health reports using AI.',
        ),
        video: '/how_it_works/create_ai_health_report.mp4',
        videoTitle: t(
          'howItWorks.healthReport.videoTitle',
          'Watch how health report creation works',
        ),
      },
    ];
  };

  const processes = getProcessImages();

  return (
    <Container className='py-5'>
      <Row className='text-center mb-5'>
        <Col>
          <h1 className='display-4 fw-bold'>{t('howItWorks.mainTitle', 'How It Works')}</h1>
          <p className='lead'>
            {t(
              'howItWorks.subtitle',
              'Explore the processes that power our medical document management system',
            )}
          </p>
        </Col>
      </Row>

      {processes.map((process, index) => (
        <Row key={process.id} className='mb-5'>
          <Col lg={12} className='mb-4'>
            <h2 className='h3 fw-bold'>{process.title}</h2>
            <p>{process.description}</p>
          </Col>
          <Col lg={12} className='mb-4'>
            <div className='process-image-container'>
              <Image src={process.image} alt={process.title} fluid className='shadow-lg rounded' />
            </div>
          </Col>
          <Col lg={12}>
            <div className='video-container'>
              <h3 className='h5 mb-3'>{process.videoTitle}</h3>
              <div className='ratio ratio-16x9'>
                <video controls className='rounded shadow'>
                  <source src={process.video} type='video/mp4' />
                  {t(
                    'howItWorks.videoNotSupported',
                    'Your browser does not support the video tag.',
                  )}
                </video>
              </div>
            </div>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default HowItWorksPage;
