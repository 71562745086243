import React, { useMemo } from 'react';
import CountUp from 'react-countup';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ArrowNarrowRightIcon, BellIcon, CheckIcon, CreditCardIcon } from '@heroicons/react/solid';
import cx from 'classnames';

const ProductFeatures = ({ product }) => {
  const { name, periodType } = product;

  const {
    t,
    i18n: { exists },
  } = useTranslation();

  const productPlanKey = `products.plan.${name}`;

  const features = useMemo(() => {
    const featureKey = `${productPlanKey}.features`;
    const featurePeriodKey = `${productPlanKey}.features-by-period.${periodType}`;

    return [
      ...(exists(featureKey) ? t(featureKey, { returnObjects: true }) : []),
      ...(exists(featurePeriodKey) ? t(featurePeriodKey, { returnObjects: true }) : []),
    ];
  }, [periodType, productPlanKey, t, exists]);

  return features.map((text, index) => (
    <div
      key={`feature-${index}`}
      className={cx('d-flex align-items-center', { 'mb-3': index < features.length - 1 })}
    >
      <CheckIcon className='icon icon-sm me-2' />
      <span>{text}</span>
    </div>
  ));
};

const PaymentMethods = ({ isOneOffProduct, isFreeProduct }) => {
  const { t } = useTranslation();
  // Don't display payment methods for free plan
  if (isFreeProduct) {
    return null;
  }
  return (
    <div className='mt-3'>
      <div className='d-flex flex-column align-items-center gap-2'>
        {/* Credit card logos */}
        <div className='text-dark px-3 py-2 d-flex align-items-center justify-content-center'>
          <img
            src='/visa.png'
            alt='Visa'
            className='img-fluid me-2'
            style={{ maxWidth: '40px', width: '100%' }}
          />
          <img
            src='/master_card.png'
            alt='MasterCard'
            className='img-fluid'
            style={{ maxWidth: '40px', width: '100%' }}
          />
        </div>
        {/* One-off payment methods */}
        {isOneOffProduct && (
          <div className='text-dark px-3 py-2 d-flex flex-column align-items-center'>
            <div className='mb-2 text-center' style={{ maxWidth: '200px', width: '100%' }}>
              <img
                src='/flagi_Przelewy24_1.png'
                alt='Przelewy24'
                className='img-fluid mb-2'
                style={{ maxHeight: '100px', width: 'auto' }}
              />
            </div>
            <img
              src='/blik_logo.svg'
              alt='BLIK'
              className='img-fluid'
              style={{ maxWidth: '60px', width: '100%' }}
            />
          </div>
        )}

        {/* Link logo */}
        <div className='text-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 72 24'
            fill='none'
            className='img-fluid'
            style={{ maxWidth: '72px', width: '100%', height: 'auto' }}
          >
            <title>Link</title>
            <path
              fill='currentColor'
              d='M36.12 3.67683c0-1.12801.9504-2.04481 2.0688-2.04481 1.1184 0 2.0688.9216 2.0688 2.04481 0 1.1232-.9168 2.0688-2.0688 2.0688-1.152 0-2.0688-.9168-2.0688-2.0688ZM29.9808 1.92001h3.6V22.08h-3.6V1.92001ZM40.008 7.68001h-3.6288V22.08h3.6288V7.68001ZM66.096 14.3904c2.7312-1.68 4.5888-4.1808 5.3232-6.71516h-3.6288c-.9456 2.41916-3.1152 4.23836-5.5008 5.01116V1.91523h-3.6288V22.0752h3.6288V16.08c2.7696.6912 4.9584 3.0864 5.7072 5.9952h3.6528c-.5568-3.0528-2.6448-5.9088-5.5536-7.6848ZM46.44 9.29283c.9504-1.2624 2.8032-1.99681 4.3056-1.99681 2.8032 0 5.1216 2.04961 5.1264 5.14558v9.6336h-3.6288v-8.832c0-1.272-.5664-2.7408-2.4048-2.7408-2.16 0-3.4032 1.9152-3.4032 4.1568v7.4256h-3.6288V7.68962H46.44v1.60321Z'
            ></path>
            <path
              fill='#00D66F'
              d='M12 24c6.6274 0 12-5.3726 12-12 0-6.62743-5.3726-12-12-12C5.37259 0 0 5.37257 0 12c0 6.6274 5.37259 12 12 12Z'
            ></path>
            <path
              fill='#011e0f'
              d='M11.4479 4.80005H7.74707c.72 3.0096 2.82243 5.58235 5.45283 7.19995-2.6352 1.6176-4.73283 4.1904-5.45283 7.2h3.70083c.9168-2.784 3.456-5.2032 6.576-5.6976v-3.0095c-3.1248-.4896-5.664-2.90885-6.576-5.69285Z'
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

const ProductPricingItem = ({ isSubscriptionActive, subscription, product, selectProduct }) => {
  const { price, currency, periodType, name } = product;

  const { t } = useTranslation();

  const handleSelectProduct = (e) => {
    if (selectProduct) {
      e.preventDefault();
      selectProduct(product);
    }
  };

  const isYearPeriod = periodType === 'year';
  const isOneOffProduct = periodType === 'one-off';
  const isFreeProduct = price === 0;

  const priceMonthly = useMemo(() => {
    if (isOneOffProduct) {
      return Math.ceil(price / 100);
    }
    const priceMonthly = isYearPeriod ? price / 12 : price;
    return Math.ceil(priceMonthly / 100);
  }, [price, isYearPeriod, isOneOffProduct]);

  const productPlanKey = `products.plan.${name}`;

  const isPremiumProduct = t(`${productPlanKey}.tag`).toLowerCase() === 'pro';

  const variant = isPremiumProduct ? 'secondary' : 'gray-800';

  return (
    <Card
      className={cx('mb-4 mb-xl-0 h-100', {
        'bg-gray-50': isSubscriptionActive && !isOneOffProduct,
      })}
    >
      <Card.Header className='border-gray-100 py-5 px-4'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex mb-3'>
            <h5 className='mb-0'>{currency}</h5>
            <span className={`display-2 text-${variant} mb-0`}>
              <CountUp start={0} end={priceMonthly} duration={0.5} />
            </span>
            {!isOneOffProduct && (
              <h6 className='fw-normal align-self-end'>/{t(`periods.month`).toLowerCase()}</h6>
            )}
          </div>
          {isYearPeriod && (
            <div className='d-flex mb-3 text-gray-600 fw-bold'>
              <span className='mb-0'>{currency}</span>
              <span>
                <span className='display-4'>{price / 100}</span>
                <span className='align-self-end'>/{t(`periods.year`).toLowerCase()}</span>
              </span>
            </div>
          )}
        </div>
        <h4 className='mb-3 text-black'>{t(`${productPlanKey}.title`)}</h4>
        <p className='mb-0'>{t(`${productPlanKey}.description`)}</p>
        {/* Payment Methods */}
        <PaymentMethods isOneOffProduct={isOneOffProduct} isFreeProduct={isFreeProduct} />
      </Card.Header>
      <Card.Body className='py-5 px-4'>
        <ProductFeatures product={product} />
      </Card.Body>
      <Card.Footer className='border-gray-100 d-grid px-4 pb-4'>
        {isSubscriptionActive && !isOneOffProduct ? (
          <Button variant='outline-success' className='fw-bold' disabled>
            {t(`products.subscription-active`)}
            {subscription &&
              subscription?.periodType !== periodType &&
              ' - ' + t(`periodically.${subscription.periodType}`)}
          </Button>
        ) : isYearPeriod ? (
          <Button variant='outline-primary' disabled>
            {t(`products.button-available-soon`)} <BellIcon className='icon icon-xs' />
          </Button>
        ) : (
          <Button
            variant='logo-green'
            onClick={handleSelectProduct}
            type='button'
            className='d-flex align-items-center justify-content-center'
          >
            {isOneOffProduct ? t('payment.buy-now') : t(`${productPlanKey}.button`)}
            <ArrowNarrowRightIcon className='icon icon-xs ms-3' />
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

export default React.memo(ProductPricingItem);
