import React, { useCallback, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  useFetchStripeCheckoutMutation,
  useFetchOneTimeCheckoutMutation,
  useConsentConsumerRightsMutation,
} from '#store';
import SubscriptionCheckoutModal from './SubscriptionCheckoutModal';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const STRIPE_TEST_PUBLIC_KEY = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY;

const SubscriptionCheckout = ({ product, onUnselectProduct }) => {
  const { t } = useTranslation();
  const [subscriptionCheckout] = useFetchStripeCheckoutMutation();
  const [oneTimeCheckout] = useFetchOneTimeCheckoutMutation();
  const [consentConsumerRights] = useConsentConsumerRightsMutation();
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);

  const handlePayment = useCallback(
    async ({ consent }) => {
      if (!consent) return;

      const { price_id, periodType } = product;
      const isOneOffProduct = periodType === 'one-off';

      if (!price_id) {
        setError(t('payment-errors.payment-no-price-id'));
        setShowError(true);
        return;
      }

      try {
        // run parallel consent and stripe loading
        const consentLoading = consentConsumerRights(consent);
        const stripeLoading = loadStripe(STRIPE_TEST_PUBLIC_KEY);

        const stripe = await stripeLoading;
        const consentResult = await consentLoading;

        if (!consentResult?.data?.consent) return;

        // Use the appropriate checkout endpoint based on product type
        if (isOneOffProduct) {
          try {
            const response = await oneTimeCheckout({
              price_id,
            });

            if (response.data) {
              if (response.data.status === 'success' && response.data.redirect_url) {
                const redirectUrl = new URL(response.data.redirect_url);
                redirectUrl.searchParams.append('fromCheckout', 'true');
                window.location.href = redirectUrl.toString();
                return;
              } else if (response.data.checkout_url) {
                window.location.href = response.data.checkout_url;
                return;
              } else if (response.data.session_id) {
                await stripe.redirectToCheckout({ sessionId: response.data.session_id });
                return;
              }
            }
            setError(t('payment-errors.payment-no-session'));
            setShowError(true);
          } catch (oneTimeError) {
            setError(t('payment-errors.payment-checkout-failed'));
            setShowError(true);
          }
        } else {
          try {
            const response = await subscriptionCheckout({ price_id });

            if (response.data && response.data.session_id) {
              await stripe.redirectToCheckout({ sessionId: response.data.session_id });
            } else {
              setError(t('payment-errors.payment-no-session'));
              setShowError(true);
            }
          } catch (subscriptionError) {
            setError(t('payment-errors.payment-checkout-failed'));
            setShowError(true);
          }
        }
      } catch (e) {
        setError(t('payment-errors.payment-general'));
        setShowError(true);
      }
    },
    [consentConsumerRights, subscriptionCheckout, oneTimeCheckout, product, t],
  );

  return (
    <>
      <SubscriptionCheckoutModal
        onUnselectProduct={onUnselectProduct}
        onPayment={handlePayment}
        product={product}
      />
      <ToastContainer position='top-end' className='p-3' style={{ zIndex: 1070 }}>
        <Toast
          onClose={() => setShowError(false)}
          show={showError}
          delay={5000}
          autohide
          bg='danger'
        >
          <Toast.Header>
            <strong className='me-auto'>{t('errors.title')}</strong>
          </Toast.Header>
          <Toast.Body className='text-white'>{error}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default React.memo(SubscriptionCheckout);
