import React, { useState, useMemo } from 'react';
import { Col, Row, Card, FormCheck, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFetchProductsQuery, useFetchSubscriptionStatusQuery } from '#store';
import ProductsPricingItem from './ProductsPricingItem';
import PriceList from './PriceList';

const ProductsPricing = ({ showActiveProducts = false, onSelectProduct }) => {
  const { t } = useTranslation();

  const [isPriceMonthly, setIsPriceMonthly] = useState(true);

  const { data: products = [], isLoading: isLoadingProducts } = useFetchProductsQuery(
    { getAllProducts: true },
    { refetchOnMountOrArgChange: true },
  );

  const { data: subscriptionStatus, isLoading: isLoadingSubscriptionStatus } =
    useFetchSubscriptionStatusQuery(undefined, {
      skip: !showActiveProducts,
      refetchOnMountOrArgChange: true,
    });

  const currentSubs = useMemo(() => subscriptionStatus?.current_subs || [], [subscriptionStatus]);

  const filteredProducts = useMemo(() => {
    if (!products) {
      return [];
    }

    // Filter to show only one-off payment products
    return products.filter((product) => {
      // Include free plan - commented out
      // if (product.price === 0) {
      //   return true;
      // }
      // Always include one-off payment products, regardless of active status
      if (product.periodType === 'one-off') {
        return true;
      }
      // Exclude all subscription products for now
      return false;
    });
  }, [products]);

  // Separate subscription products from one-off products
  const { subscriptionProducts, oneOffProducts } = useMemo(() => {
    const subscriptionProducts = [];
    const oneOffProducts = [];
    // Make sure to include all one-off products from the original products list
    products.forEach((product) => {
      if (product.periodType === 'one-off') {
        oneOffProducts.push(product);
      }
    });
    return { subscriptionProducts, oneOffProducts };
  }, [filteredProducts, products]);

  // Filter subscription products based on the selected period type
  // This will be an empty array since we're not showing subscription products
  const visibleSubscriptionProducts = useMemo(
    () => [],
    [isPriceMonthly], // Keep the dependency to avoid errors
  );

  // All products to display (only one-off products for now)
  const allVisibleProducts = useMemo(() => {
    // Only include one-off products
    return [...oneOffProducts];
  }, [oneOffProducts]);

  const currency = allVisibleProducts[0]?.currency || 'PLN';

  // Don't show the free product
  const showFreeProduct = false;

  if (isLoadingProducts || isLoadingSubscriptionStatus) {
    return (
      <div className='text-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <Card border='0' className='shadow mb-5'>
      <Card.Body>
        <Row className='text-gray justify-content-center'>
          {allVisibleProducts?.map((product) => {
            const subscriptions = currentSubs.filter(({ sub_type }) => sub_type === product.name);
            const subscription =
              subscriptions?.find(({ periodType }) => periodType === product.periodType) ||
              subscriptions[0];
            // For one-off products, don't mark them as active even if purchased before
            const isOneOffProduct = product.periodType === 'one-off';
            const isSubscriptionActive = isOneOffProduct ? false : Boolean(subscription);

            return (
              <Col
                xs={12}
                md={6}
                xl={4}
                key={product.product_id}
                className='mt-3 mx-auto'
                style={{ maxWidth: '400px' }}
              >
                <ProductsPricingItem
                  product={product}
                  selectProduct={onSelectProduct}
                  isSubscriptionActive={isSubscriptionActive}
                  subscription={subscription}
                />
              </Col>
            );
          })}
          <p className='font-small text-muted text-center'>
            {t('products.subscription-credits-explanation')}
          </p>
          <Col xs={12}>
            <PriceList />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default React.memo(ProductsPricing);
